import React, { useState } from "react"
import { navigate } from 'gatsby'
import { Container } from "./styles"

export default function Newsletter() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  function handleValidation() {
    if (typeof window !== 'undefined'){
      if (name === '') {
        window.dataLayer.push({
          'event' : 'error',
          'field' : 'name'
        });
      }
      else if(email === ''){
        window.dataLayer.push({
          'event' : 'error',
          'field' : 'email'
        });
      }
    }
  }

  function handleSubmit(e) {
    
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "newsletter",
        "nome": name,
        "email": email
      })
    })
    .then(() => {
      setName('')
      setEmail('')
      alert('Obrigado por se inscrever!')
      navigate('/home/1?newsletter=success')
    })
  }

  return (

    <Container>
      <h3 style={{marginTop: 0}}>Newsletter</h3>
      <p>Receba conteúdos mensais sobre o melhor do marketing digital.</p>
      <form name="newsletter" onSubmit={handleSubmit}>
        <input 
          type="hidden" 
          name="form-name" 
          value="newsletter" 
          />
        <input 
          type="text" 
          name="nome" 
          placeholder="Seu nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        ></input>
        <input 
          type="email" 
          name="email"
          value={email} 
          placeholder="Seu melhor email"
          onChange={(e) => setEmail(e.target.value)}
          required
        ></input>
        <button type="submit" onClick={handleValidation}>Enviar</button>
      </form>
    </Container>
  )
}