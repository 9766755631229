import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import kebabCase from "lodash/kebabCase"

import Header from '../components/header'
import Card from '../components/card'
import Newsletter from '../components/newsletter'
//import dataLayer from '../components'

import { 
  PostContainer, 
  FlexBlogContainer,
  SearchBox,
  SearchBar,
  Categories,
  CategoryChip,
  Pagination,
  PageLink,
  GatsbyImg,
  MobileBox,
  CategoryList
} from '../styles/blog'

export default function IndexPage({ data, location, pageContext }) {

  if (typeof window !== 'undefined'){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : 'gtm.dom',
      'pageType' : 'Home',
      'hostName' : 'blog.rocky.ag',
      'pagePath' : location.pathname,
      'pageReferrer' : document.referrer
    });
  }
  

  const siteTitle = "Rocky Marketing Digital"
  const posts = data.postList.edges

  const { numPages } = pageContext

  const categories = data.tagGroup.group
  const wallpaper = data.allFile.edges[0].node.childImageSharp.fluid

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const createPagination = () => {
    let pagination = []

    for (let i = 1; i <= numPages; i++) {
      pagination.push(<PageLink to={`/pagina/${i}`} path={location.pathname}>{i}</PageLink>)
    }
    return pagination
  }

  const handleInputChange = event => {
    const query = event.target.value

    const posts = data.postList.edges || []

    const filteredData = posts.filter(post => {
      const { description, title, tags } = post.node.frontmatter
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        tags
          .join("")
          .toLowerCase()
          .includes(query.toLowerCase())
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData.length !== 0 && query !== emptyQuery
  const allPosts = hasSearchResults ? filteredData : posts 

  return(

    <Layout location={location} title={siteTitle}>
      <SEO
        title={`Home: Página ${pageContext.currentPage}`}
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        meta="Que tal aprender mais sobre marketing com uma agência de marketing digital que tem performance nas veias? Confira nosso blog"
        rich={{
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Rocky Marketing Digital - Blog",
          "url": "https://rocky.ag/blog",
          "logo": "https://rocky.ag/wp-content/themes/rocky/imagens/logo_white.png",
          "sameAs" : ["https://rocky.ag/","https://www.linkedin.com/company/rocky-ag","https://www.instagram.com/agencia.rocky/"]
        }}
      />
    
      {/* <div> */}
      <GatsbyImg
        fluid={wallpaper}
        imgStyle={{
          objectPosition: "50% 70%"
        }}
        alt="Banner - Blog da Rocky"
        title="Banner - Blog da Rocky"
      />

      <Header 
        location={location} 
        borderRadius={false}
      />
      {/* </div> */}

      <FlexBlogContainer>
        <MobileBox>
          <Newsletter />
        </MobileBox>
        <PostContainer>
          {allPosts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const { description } = node.frontmatter
            
            return (
              <Card 
                title={title}
                description={description}
                slug={node.fields.slug}
                tags={node.frontmatter.tags}
                thumbnail={node.frontmatter.thumbnail.publicURL}
              />
            )
          })}

          <Pagination>
            {createPagination()}
          </Pagination>
        </PostContainer>

        <SearchBox>
          <SearchBar>
            <input
              className="searchInput"
              type="text"
              aria-label="Search"
              placeholder="Pesquisar"
              onChange={handleInputChange}
            />
          </SearchBar>
          
          <Newsletter />

          <Categories>
            <h3 style={{marginTop: 0}}>Categorias</h3>
            <CategoryList>
              {categories.map((tag, index) => (
                <li key={index}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}`} style={{color: "#000"}}>
                    <CategoryChip label={`${tag.fieldValue} (${tag.totalCount})`} clickable />
                  </Link>
                </li>
              ))}
            </CategoryList>
          </Categories>
        </SearchBox>
    
      </FlexBlogContainer>
    </Layout>  

  )
}

export const pageQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {name: {eq: "wallpaper2"}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid (maxWidth: 1700, maxHeight: 550) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
    postList: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip
      ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            thumbnail {
              publicURL
            }   
          }
        }
      }
    }
    tagGroup: allMdx {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
