import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 30px;
  padding-bottom: 0;

  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 1px 3px 0px rgba(0,0,0,0.12);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  input {
    border-radius: 20px;
    margin-top: 10px;
    padding: 10px;
    border: none;
    width: 11vw;
    font-size: 2vh;
    background-color: #e0e0e0;

    :focus {
      outline: none; 
      border: none;
      border-radius: 20px; 
      box-shadow: 
        0px 0px 1px rgba(245, 242, 66,1),
        0px 0px 2px rgba(245, 242, 66,1),
        0px 0px 3px rgba(245, 242, 66,1); 
    }

    @media(max-width: 768px) {
      width: 90%;
    }
  }

  button {
    margin-top: 10px;
    cursor: pointer;
    border-radius: 20px;
    padding: 5px;
    transition-duration: 0.4s;
    width: 8vw;
    background-color: white;
    color: black;
    border: 2px solid #555555;

    :hover {
      background-color: #555555;
      color: white;
    }

    @media(max-width: 768px) {
      width: 90%;
    }
  }

  @media(max-width: 768px) {
      width: 95%;
  }
`

export const Error = styled.p`
  color: red;
  font-size: 14px;
`